<template>
    <div>
        <CustomMeasurement
            v-if="customMeasurements"
            v-bind="$props"
            :measurements="customMeasurements"
        />

        <component
            :is="customMeasurementComponent"
            v-else-if="customMeasurementComponent"
            v-bind="$props"
        />

        <template v-else>
            <MeasurementItem
                v-if="humidity"
                :title="humidity.title"
                :datetime="humidity.lastUpdated"
                :link-to="
                    temperature && !isShared
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                              },
                              query: {
                                  measurement: [humidity.key, temperature.key],
                              },
                          }
                        : humidity.link
                "
            >
                <template #icon>
                    <DropIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.humidity') }}:

                    <h3 class="sensor-data-percentage">
                        {{ humidity.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="temperature"
                :title="temperature.title"
                :datetime="temperature.lastUpdated"
                :link-to="
                    humidity && !isShared
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                              },
                              query: {
                                  measurement: [humidity.key, temperature.key],
                              },
                          }
                        : temperature.link
                "
            >
                <template #icon>
                    <ThermometerIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.temperature') }}:

                    <h3 class="sensor-data-temperature">
                        {{ temperature.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="distance"
                :title="distance.title"
                :datetime="distance.lastUpdated"
                :link-to="distance.link"
            >
                <template #icon>
                    <DistanceIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.distance') }}:

                    <h3 class="sensor-data-distance">
                        {{ distance.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="mass"
                :title="mass.title"
                :datetime="mass.lastUpdated"
                :link-to="mass.link"
            >
                <template #icon>
                    <ScalesIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.mass') }}:

                    <h3 class="sensor-data-mass">
                        {{ mass.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="odometer"
                :title="odometer.title"
                :datetime="odometer.lastUpdated"
                :link-to="odometer.link"
            >
                <template #icon>
                    <DistanceIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.odometer') }}:

                    <h3 class="sensor-data-odometer">
                        {{ odometer.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="speed"
                :title="speed.title"
                :datetime="speed.lastUpdated"
                :link-to="speed.link"
            >
                <template #icon>
                    <SpeedIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.speed') }}:

                    <h3 class="sensor-data-speed">
                        {{ speed.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="co2"
                :title="co2.title"
                :datetime="co2.lastUpdated"
                :link-to="co2.link"
            >
                <template #icon>
                    <CO2Icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.co2') }}:

                    <h3 class="sensor-data-co2">
                        {{ co2.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <MeasurementItem
                v-if="voc"
                :title="voc.title"
                :datetime="voc.lastUpdated"
                :link-to="voc.link"
            >
                <template #icon>
                    <VocIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('shared.measurements.voc') }}:

                    <h3 class="sensor-data-voc">
                        {{ voc.value }}
                    </h3>
                </template>
            </MeasurementItem>

            <BatteryMeasurementsFragment v-bind="$props" />

            <MeasurementItem
                v-if="hasRunningTimeAccess && runningTime"
                :datetime="runningTime.lastUpdated"
                :link-to="
                    !isShared
                        ? {
                              name: 'runningTimeChart',
                              params: { id: tracker.id },
                          }
                        : null
                "
            >
                <template #icon>
                    <StopwatchIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('runningTime') }}:

                    <h3>
                        {{ runningTime.textDuration }}
                    </h3>
                </template>
            </MeasurementItem>
        </template>

        <DriverMeasurementFragment
            v-if="isDriverMeasurementVisible"
            v-bind="$props"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper, measurementHelper } from '../utils'
import AgrolaSiloMeasurement from './AgrolaSiloMeasurement'
import AlertMeasurement from './AlertMeasurement'
import AnimalMeasurement from './asset-types/animal/AnimalMeasurement'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import BinMeasurement from './BinMeasurement'
import CM1Measurement from './CM1Measurement'
import CM2BrigthnessMeasurement from './CM2BrigthnessMeasurement'
import CM2HumidityMeasurement from './CM2HumidityMeasurement'
import CM2SimpleMeasurement from './CM2SimpleMeasurement'
import CO2Icon from './icons/CO2Icon'
import CarMeasurement from './CarMeasurement'
import ClueyLeakageMeasurement from './ClueyLeakageMeasurement'
import CustomMeasurement from './CustomMeasurement'
import DigitalInputMeasurement from './DigitalInputMeasurement'
import DistanceIcon from './icons/DistanceIcon'
import Dragino22222Measurement from './Dragino22222Measurement'
import DriverMeasurementFragment from './DriverMeasurementFragment'
import DropIcon from './icons/DropIcon'
import DynamicBinCollectionMeasurement from './DynamicBinCollectionMeasurement'
import DynamicBinMeasurement from './asset-types/dynamic-bin/DynamicBinMeasurement'
import EcocoachPowerTrolleyMeasurement from './EcocoachPowerTrolleyMeasurement'
import FenceGuardMeasurement from './FenceGuardMeasurement'
import FencyBoyMeasurement from './FencyBoyMeasurement'
import FlatRoofLightMeasurement from './FlatRoofLightMeasurement'
import FlatRoofMeasurement from './FlatRoofMeasurement'
import HeatingSystemMeasurement from './HeatingSystemMeasurement'
import LevelMeasurement from './LevelMeasurement'
import MachineMeasurement from './MachineMeasurement'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import MoistureMeasurements from './MoistureMeasurements'
import ParkingSpaceMeasurement from './ParkingSpaceMeasurement'
import PersonIcon from './icons/PersonIcon'
import RoadMaintenanceMeasurement from './RoadMaintenanceMeasurement'
import SaltMeasurement from './SaltMeasurement'
import SbbBinMeasurement from './asset-types/sbb-bin/SbbBinMeasurement'
import ScalesIcon from './icons/ScalesIcon'
import SiloMeasurement from './SiloMeasurement'
import SolarPowerMeasurement from './SolarPowerMeasurement'
import SpeedIcon from './icons/SpeedIcon'
import StopwatchIcon from './icons/StopwatchIcon'
import TeltonikaRouterMeasurement from './TeltonikaRouterMeasurement'
import ThermometerIcon from './icons/ThermometerIcon'
import TruckMeasurement from './TruckMeasurement'
import VocIcon from './icons/VocIcon'
import WeatherStationMeasurement from './WeatherStationMeasurement'
import Ws100Measurement from './Ws100Measurement'

const customMeasurementComponents = {
    'agrola-silo': AgrolaSiloMeasurement,
    'cluey-leakage': ClueyLeakageMeasurement,
    'cm2-brigthness': CM2BrigthnessMeasurement,
    'cm2-humidity': CM2HumidityMeasurement,
    'cm2-rope': CM2HumidityMeasurement,
    'cm2-simple': CM2SimpleMeasurement,
    'digital-input': DigitalInputMeasurement,
    'dragino-22222': Dragino22222Measurement,
    'dynamic-bin': DynamicBinMeasurement,
    'dynamic-bin-collection': DynamicBinCollectionMeasurement,
    'ecocoach-power-trolley': EcocoachPowerTrolleyMeasurement,
    'fence-guard': FenceGuardMeasurement,
    'fence-guard-simulated': FenceGuardMeasurement,
    'fency-boy': FencyBoyMeasurement,
    'flat-roof': FlatRoofMeasurement,
    'flat-roof-light': FlatRoofLightMeasurement,
    'flat-roof-probe': FlatRoofMeasurement,
    'heating-system': HeatingSystemMeasurement,
    'parking-space': ParkingSpaceMeasurement,
    'road-maintenance': RoadMaintenanceMeasurement,
    'sbb-bin': SbbBinMeasurement,
    'weather-station': WeatherStationMeasurement,
    alert: AlertMeasurement,
    animal: AnimalMeasurement,
    bin: BinMeasurement,
    car: CarMeasurement,
    cm1: CM1Measurement,
    level: LevelMeasurement,
    machine: MachineMeasurement,
    moisture: MoistureMeasurements,
    power: SolarPowerMeasurement,
    salt: SaltMeasurement,
    silo: SiloMeasurement,
    teltonika_rtu240: TeltonikaRouterMeasurement,
    truck: TruckMeasurement,
    ws100: Ws100Measurement,
}

export default {
    name: 'MeasurementsList',
    components: {
        BatteryMeasurementsFragment,
        CO2Icon,
        CustomMeasurement,
        DistanceIcon,
        DriverMeasurementFragment,
        DropIcon,
        MeasurementItem,
        PersonIcon,
        ScalesIcon,
        SpeedIcon,
        StopwatchIcon,
        ThermometerIcon,
        VocIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('auth', ['hasRunningTimeAccess']),
        ...mapGetters('tracker', ['assetTypesById']),
        co2() {
            return this.getMeasurementItem('co2')
        },
        customMeasurementComponent() {
            return customMeasurementComponents[
                this.tracker.asset_details?.asset_type_type
            ]
        },
        customMeasurements() {
            return (
                this.tracker.asset_type?.measurements?.items ??
                this.assetTypesById[this.tracker.asset_details?.asset_type]
                    ?.measurements?.items
            )
        },
        distance() {
            return this.getMeasurementItem('distance')
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        isDriverMeasurementVisible() {
            return (
                !this.customMeasurements &&
                this.tracker.asset_details?.asset_type_type !== 'truck'
            )
        },
        mass() {
            return this.getMeasurementItem('mass')
        },
        odometer() {
            const item =
                this.getMeasurementItem('tachograph_odometer') ??
                this.getMeasurementItem('odometer')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        speed() {
            const item = this.getMeasurementItem('speed')
            if (item) {
                item.value = measurementHelper.converters.speed(item.value)
            }
            return item
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        voc() {
            return this.getMeasurementItem('voc')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "runningTime": "Operating hours"
    },
    "de": {
        "runningTime": "Betriebsstunden"
    },
    "fr": {
        "runningTime": "Heures de fonctionnement"
    },
    "it": {
        "runningTime": "Ore di funzionamento"
    }
}
</i18n>
