<template>
    <div>
        <MeasurementItem
            v-if="state"
            :title="state.title"
            :datetime="state.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: 'd1',
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="state.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(state.value ? 'running' : 'notRunning') }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="speed"
            :title="speed.title"
            :datetime="speed.lastUpdated"
            :link-to="speed.link"
        >
            <template #icon>
                <SpeedIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.speed') }}:

                <h3 class="sensor-data-speed">
                    {{ speed.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />

        <MeasurementItem
            v-if="externalVoltage"
            :title="externalVoltage.title"
            :datetime="externalVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: externalVoltage.key,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.external_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ externalVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="odometer"
            :title="odometer.title"
            :datetime="odometer.lastUpdated"
            :link-to="odometer.link"
        >
            <template #icon>
                <DistanceIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.odometer') }}:

                <h3 class="sensor-data-odometer">
                    {{ odometer.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="hasRunningTimeAccess && runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
            :link-to="{
                name: 'runningTimeChart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <StopwatchIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper, measurementHelper } from '../utils'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DistanceIcon from './icons/DistanceIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SpeedIcon from './icons/SpeedIcon'
import StopwatchIcon from './icons/RefreshArrowsIcon'

export default {
    name: 'MachineMeasurement',
    components: {
        BatteryMeasurementsFragment,
        DistanceIcon,
        FilledCircleIcon,
        FlashIcon,
        MeasurementItem,
        SpeedIcon,
        StopwatchIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('auth', ['hasRunningTimeAccess']),
        externalVoltage() {
            return this.getMeasurementItem('external_voltage')
        },
        odometer() {
            const item =
                this.getMeasurementItem('tachograph_odometer') ??
                this.getMeasurementItem('odometer')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        speed() {
            const item = this.getMeasurementItem('speed')
            if (item) {
                item.value = measurementHelper.converters.speed(item.value)
            }
            return item
        },
        state() {
            return this.getMeasurementItem('ignition')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "running": "Running",
        "notRunning": "Not running",
        "runningTime": "Operating hours"
    },
    "de": {
        "running": "In Betrieb",
        "notRunning": "Ausser Betrieb",
        "runningTime": "Betriebsstunden"
    },
    "fr": {
        "running": "En fonctionnement",
        "notRunning": "Hors fonctionnement",
        "runningTime": "Heures de fonctionnement"
    },
    "it": {
        "running": "In uso",
        "notRunning": "Fuori uso",
        "runningTime": "Ore di funzionamento"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-item__content .no-padding-left {
    padding-left: 0;
}

.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
